import { WppTypography } from '@platform-ui-kit/components-library-react'

import { BriefContainer } from 'components/brief/BriefContainer'
import { useHasPermissions } from 'hooks/useHasPermissions'

import styles from './App.module.scss'

export function App() {
  const { hasAccessToChatAssistant } = useHasPermissions()

  if (!hasAccessToChatAssistant) {
    return null
  }

  return (
    <div className={styles.briefContainer}>
      <WppTypography style={{ marginBottom: 32 }} type="2xl-heading">
        Write Global Project Brief - Coca Cola Summer 2023
      </WppTypography>
      <BriefContainer />
    </div>
  )
}
