import { WppCard } from '@platform-ui-kit/components-library-react'

import styles from './Brief.module.scss'
import PreviewNoContent from './PreviewNoContent'

export default function BriefPreview() {
  return (
    <WppCard variant="secondary" title="Brief Preview" size="l" className={styles.briefPreviewContainer}>
      {/* <div slot="header">
        <WppTypography type="l-strong">Brief Preview</WppTypography>
      </div>
      <div slot="actions">
        <WppActionButton variant="secondary">
          <WppIconEdit />
        </WppActionButton>
      </div> */}
      <PreviewNoContent />
      {/* <PreviewOverview />
      <PreviewTargetAudience />
      <PreviewCompetitiveLandscape />
      <PreviewMakretingChannels /> */}
    </WppCard>
  )
}
