import { Flex } from 'components/common/flex/Flex'

import styles from './Brief.module.scss'
import BriefAssistant from './BriefAssistant'
import BriefPreview from './BriefPreview'

export function BriefContainer() {
  return (
    <Flex direction="row" gap={8}>
      <BriefAssistant />
      <div className={styles.verticalDivider} />
      <BriefPreview />
    </Flex>
  )
}
